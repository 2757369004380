import React from 'react';
import '../styles/style.css';
import '../styles/custom.css';

var loadjs = require('loadjs');

class Contact extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            settings: null
        };
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/data/settings.json`)
        .then((r) => r.json())
        .then((data) =>{
            this.setState({settings: data.global})
        })
        loadjs(['/js/vendor/jquery-3.2.1.min.js',
                '/js/jquery.slicknav.min.js', 
                '/js/jquery.magnific-popup.min.js',
                // '/js/owl.carousel.min.js',
                // '/js/mixitup.min.js',
                '/js/masonry.pkgd.min.js',
                '/js/jquery.bxslider/jquery.bxslider.min.js',
                '/js/jquery.bxslider/bxslider.js',
                // '/js/main.js',
           ], {returnPromise: true})
        .then(function() { window.fixOffcanvasMenu(); })
        .catch(function(pathsNotFound) { console.log("error loading scripts"); console.log(pathsNotFound) });
    }

    render() {
        var { settings } = this.state;
        return (
            <React.Fragment>
                <div class="contact__map">
                    <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49961270.01972334!2d-44.696209930502064!3d40.15578230058868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2237e03def9c41%3A0x878e2298dda9f4de!2sFigueira%20da%20Foz!5e0!3m2!1sen!2spt!4v1591231978036!5m2!1sen!2spt" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    {/* <div class="map__info"><i class="fa fa-map-marker"></i> Figueira da Foz, Portugal</div> */}
                </div>
                
                <section class="contact__section spad">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="contact__logo">
                                    <img src="/logos/contact-wowmydios.png" alt="" />
                                </div>                                
                                {
                                    settings === null ? "" :
                                    settings.contact.map((item, index) => {
                                        return (
                                            <div className="contact__info" key={index}>
                                                <h3>{item.header}</h3>
                                                <p>{item.text}</p>
                                            </div>
                                        )
                                    })
                                }
                                <div className="contact__info__social">
                                    {
                                        settings === null ? "" :
                                        settings.social.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <a href={item.url}>
                                                        <i className={"fa " + item.icon}></i> {item.text}
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div class="col-lg-8">

                                <iframe title="Form" width="100%" height="786" allowTransparency="true" frameborder="0" scrolling="no" style={{border:'none'}} src="https://www.emailmeform.com/builder/embed/o2JaN88cbchCz21w799Dd0c6m"></iframe>

                                {/* <div class="contact__form__box">
                                    <h2>Contact Form</h2>
                                    <form>
                                        <input type="text" placeholder="Your Name">
                                        <input type="text" placeholder="Your Email">
                                        <textarea placeholder="Your Messages"></textarea>
                                        <button class="site-btn">SEND MESSAGES</button>
                                    </form>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }

}

export default Contact;