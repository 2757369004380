import React from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/home';
import Contact from './pages/contact';
import About from './pages/about';
import Dioramas from './pages/dioramas';
import Diorama from './pages/diorama';
import Faq from './pages/faq';
import Disclaimer from './pages/disclaimer';
import MessageSent from './pages/messagesent';
import './styles/style.css';

import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import OrderSent from './pages/ordersent';

function App() {
  return (
    <div className="flex-wrapper">
      <Header />
      <Switch>
        <Route exact path='/'><Redirect to="/home"></Redirect></Route>
        <Route exact path='/home' component={Home}></Route>
        <Route exact path='/dioramas' component={Dioramas}></Route>
        <Route exact path='/dioramas/diorama/:id' component={Diorama}></Route>
        <Route exact path='/faq' component={Faq}></Route>
        <Route exact path='/about' component={About}></Route>
        <Route exact path='/contact' component={Contact}></Route>
        <Route exact path='/disclaimer' component={Disclaimer}></Route>
        <Route exact path='/messagesent' component={MessageSent}></Route>
        <Route exact path='/ordersent' component={OrderSent}></Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default App;