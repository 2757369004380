import React from 'react';
import '../styles/style.css';

var loadjs = require('loadjs');

class Diorama extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            diorama: null
        };
    }

    componentDidMount() {
        let { id } = this.props.match.params;
        fetch(`${process.env.PUBLIC_URL}/data/diorama.json`)
        .then((r) => r.json())
        .then((data) =>{           
            this.setState({diorama: data[id]})
            window.showSlides(1)
        })
        loadjs(['/js/vendor/jquery-3.2.1.min.js',
                '/js/jquery.slicknav.min.js', 
                '/js/jquery.magnific-popup.min.js',
                // '/js/owl.carousel.min.js',
                // '/js/mixitup.min.js',
                '/js/masonry.pkgd.min.js',
                '/js/jquery.bxslider/jquery.bxslider.min.js',
                '/js/jquery.bxslider/bxslider.js',
                // '/js/main.js',
           ], {returnPromise: true})
        .then(function() {window.fixOffcanvasMenu();})
        .catch(function(pathsNotFound) { console.log("error loading scripts"); console.log(pathsNotFound) });        
    }

    renderImageSlide(slide, index) {
        return (
            <div className="mySlides" key={index}>
                <div className="numbertext">{index + 1} / {this.state.diorama.slideshow.length}</div>
                <img alt="" className="rounded" src={slide.url} style={{width:'70%'}}></img>
            </div>
        )
    }

    renderVideoSlide(slide, index) {
        return (
            <div className="mySlides" key={index}>
                <div className="numbertext">{index + 1} / {this.state.diorama.slideshow.length}</div>
                <iframe width={slide.width} height={slide.height} src={slide.url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        )
    }

    render() {
        var { diorama } = this.state;
        return (
            diorama === null ? "" :
            <React.Fragment>
                <div className="page__banner">
                    <div className="banner__text">
                        <h2>{diorama.mainTitle}</h2>
                        <div className="site__breadcrumb">
                            {/* <a href="#">Home / </a>
                            <a href="#">Blog </a>
                            <span>/ Blog Details</span> */}
                        </div>
                    </div>
                </div>
                <div className="blog__single">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <article className="blog__post__content">
                                    <div className="post__cata">{diorama.category}</div>
                                    <h2 className="post__title">{diorama.mainSubtitle}</h2>
                                    {/* <div class="post__date">26th June, 2019</div> */}
    
                                    <div className="slideshowcontainer">
                                        {
                                            diorama.slideshow.map((image, index) => {
                                                return (
                                                    image.type === "image" ? this.renderImageSlide(image, index) : this.renderVideoSlide(image, index)
                                                )
                                            })                                            
                                        }

                                        <a className="prev" onClick={() => window.plusSlides(-1)}>&#10094;</a>
                                        <a className="next" onClick={() => window.plusSlides(1)}>&#10095;</a>                                        
                                        <div className="row">
                                            {
                                                diorama.slideshow.map((image, index) => {
                                                    var { thumbnail, url } = image
                                                    return (
                                                        <div className="column" key={index}>
                                                            <img className="demo cursor img-thumbnail" src={ thumbnail === "" ? url : thumbnail } style={{width:'100%'}} onClick={() => window.currentSlide((index + 1))} alt="" />                                                            
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    
                                    <h4>Description</h4>
                                    <p>{diorama.description}</p>
                                    
                                    <h4>High quality material used</h4>
                                    <p>{diorama.materialUsed}</p>
                                    {
                                        diorama.reviews.length <= 0 ? "" :
                                        diorama.reviews.map((review, index) => {
                                            return (
                                                <blockquote key={index}>
                                                    <p>“ {review.review} ”</p>
                                                    <h6>{review.name}<span> {review.country}</span></h6>
                                                    {
                                                        review.images.map((image, index) => {
                                                            return (
                                                                <img key={index} src={image} alt="" width="100" className="img-thumbnail"/>
                                                            )
                                                        })
                                                    }
                                                </blockquote>
                                            )
                                        })
                                    }
                                    
                                    <h4>Dimensions</h4>
                                    <div className="post__tags">
                                        <a href="">{diorama.dimensions}</a>
                                    </div>
                                    
                                    <h4>Includes</h4>
                                    <div className="post__tags">
                                    {
                                        diorama.includes.map((item, index) => {
                                            return (
                                                <a key={index} href="">{item}</a>
                                            )
                                        })
                                    }
                                    </div>
                                    
                                    <h4>Fair shipping</h4>
                                    <p>
                                    {
                                        diorama.fairShipping.map((item, index) => {                            
                                            return (
                                                <React.Fragment key={index}>
                                                    {
                                                        item
                                                    }
                                                    {
                                                        (index + 1) === diorama.fairShipping.length ? "" : <br />
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    </p>
                                    
                                </article>
                                
                                <div className="post__footer">
                                    <div className="row d-flex justify-content-center">
                                        <div className="">
                                            <div className="post__tags">
                                                <a style={{color: 'white', backgroundColor: '#5a43af'}} href="/contact">MESSAGE US</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                
                            </div> {/* <div class="col-lg-9"> */}
                            
                        </div>
                    </div>
                </div>                
            </React.Fragment>
        )
    }
}

export default Diorama;