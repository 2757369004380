import React from 'react';
import Linkify from 'react-linkify';
import '../styles/style.css';

var loadjs = require('loadjs');

class Faq extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            faqs: []
        };
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/data/faq.json`)
        .then((r) => r.json())
        .then((data) =>{
            this.setState({faqs: data})
        })
        loadjs(['/js/vendor/jquery-3.2.1.min.js',
                '/js/jquery.slicknav.min.js', 
                '/js/jquery.magnific-popup.min.js',
                // '/js/owl.carousel.min.js',
                // '/js/mixitup.min.js',
                '/js/masonry.pkgd.min.js',
                '/js/jquery.bxslider/jquery.bxslider.min.js',
                '/js/jquery.bxslider/bxslider.js',
                // '/js/main.js',
           ], {returnPromise: true})
        .then(function() {window.fixOffcanvasMenu();})
        .catch(function(pathsNotFound) { console.log("error loading scripts"); console.log(pathsNotFound) });        
    }

    render() {
        return (
            <React.Fragment>
                <div className="page__banner">
                    <div className="banner__text">
                        <h2>Frequently Asked Questions</h2>
                        <div className="site__breadcrumb">
                            {/* <a href="#">Home / </a>
                            <a href="#">Blog </a>
                            <span>/ Blog Details</span> */}
                        </div>
                    </div>
                </div>
                <div className="faq__section">
                    <div className="container">
                        {
                            this.state.faqs.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <h5>Q {index + 1}: {item.question}</h5>
                                        <p>A: <Linkify>{ item.answer }</Linkify></p>
                                        <div><p>&nbsp;</p></div>
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default Faq;