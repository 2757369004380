import React from 'react';
import '../styles/style.css';

var loadjs = require('loadjs');

class Dioramas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dioramas: []
        };
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/data/diorama.json`)
        .then((r) => r.json())
        .then((data) =>{
            this.setState({dioramas: data})
        })
        loadjs(['/js/vendor/jquery-3.2.1.min.js',
                '/js/jquery.slicknav.min.js', 
                '/js/jquery.magnific-popup.min.js',
                // '/js/owl.carousel.min.js',
                // '/js/mixitup.min.js',
                '/js/masonry.pkgd.min.js',
                '/js/jquery.bxslider/jquery.bxslider.min.js',
                '/js/jquery.bxslider/bxslider.js',
                // '/js/main.js',
           ], {returnPromise: true})
        .then(function() {window.fixOffcanvasMenu();})
        .catch(function(pathsNotFound) { console.log("error loading scripts"); console.log(pathsNotFound) });        
    }

    render() {
        return (
            <React.Fragment>
                <div className="page__banner">
                    <div className="banner__text">
                    <h2>DIORAMAS</h2>
                    <div className="site__breadcrumb">
                            {/* <a href="#">Home / </a>
                            <span>DIORAMAS</span> */}
                        </div>
                    </div>
                </div>
                <section className="blog__section">
		            <div className="container">
			            <div className="row blog_row">
                            {
                                this.state.dioramas.map((dio, index) => {
                                    return (
                                        <div key={index} className="col-lg-4 col-sm-6 blog__grid">
                                            <div className="blog__item">
                                                <a href={"/dioramas/diorama/" + index}><img className="rounded" src={dio.slideshow[0].url} alt="" /></a>
                                                <div className="blog__item--cata">
                                                    <a href={"/dioramas/diorama/" + index}>{dio.mainTitle}</a>
                                                </div>
                                                <h3><a href={"/dioramas/diorama/" + index}>{dio.mainSubtitle}</a></h3>
                                                {/* <div class="blog__item--date">26th June, 2019</div> */}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }

}

export default Dioramas;