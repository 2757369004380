import React from 'react';
import '../styles/custom.css';
import '../styles/style.css';

var loadjs = require('loadjs');

class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            feedback: [],
            diorama: [],
            lightbox: false,
            image: [],
            settings: null
        };
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/data/settings.json`)
        .then((r) => r.json())
        .then((data) =>{
            this.setState({settings: data.home})
        })
        fetch(`${process.env.PUBLIC_URL}/data/feedback.json`)
        .then((r) => r.json())
        .then((data) =>{
            this.setState({feedback: data})
            // window.fixSlider();
        })
        fetch(`${process.env.PUBLIC_URL}/data/diorama.json`)
        .then((r) => r.json())
        .then((data) =>{
            this.setState({diorama: data})
            window.fixGallery();
            // window.fixLightbox();
            window.fixSetBg();            
        })        
        loadjs(['/js/vendor/jquery-3.2.1.min.js',
                '/js/jquery.slicknav.min.js', 
                '/js/jquery.magnific-popup.min.js',
                // '/js/owl.carousel.min.js',
                // '/js/mixitup.min.js',
                '/js/masonry.pkgd.min.js',
                '/js/jquery.bxslider/jquery.bxslider.min.js',
                '/js/jquery.bxslider/bxslider.js',
                // '/js/main.js',
           ], {returnPromise: true})
        .then(function() {
            window.fixOffcanvasMenu();
        })
        .catch(function(pathsNotFound) { console.log("error loading scripts"); console.log(pathsNotFound) });        
    }

    delayFixFooter() {
        // setTimeout(() => { this.fixFooter() }, 500);
    }

    gridSize(item) {
        if (item.gridSize === -1)
            return ""
        else
            return "grid--" + item.gridSize
    }

    render() {        
        var { settings } = this.state;
        return (            
            <React.Fragment>
                <section className="hero">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 m-auto">
                                <h3 className="text-center wrap-new-line">{ settings === null ? "" : settings.welcome }</h3>
                            </div>
                        </div>
                        {
                            this.state.feedback.length <= 0 ? "" :
                            <ul className="bxslider sliderStyle">
                                {
                                    this.state.feedback.map((item, index) => {
                                        return(
                                            <div key={index}>
                                                "{item.message}"<br /><span className="myBold">{item.buyer}</span><br />
                                                <div align="center"></div>
                                            </div>
                                        );
                                    })
                                }
                            </ul>
                        }
                    </div>                                              
                </section>
                <section className="gallery__section">
                    <div className="filter-controls">
                        <ul>
                            <li className="active" data-filter=".recent">Recent</li>
                            {
                                this.state.settings === null ? "" :
                                this.state.settings.categories.map((item, index) => {
                                    return (
                                        <li key={index} data-filter={"." + item.slug}>{item.name}</li>
                                    )
                                })
                            }
                            <li data-filter="*">ALL</li>
                        </ul>
                    </div>
                    <div className="gallery__warp">
                       {
                           this.state.diorama.map((item, index) => {
                                    return (
                                        <div key={index} className={"mix grid__item " + this.gridSize(item) + " " + item.type} style={{}}>
                                            <a className="gallery_img set-bg a-rounded" href={"/dioramas/diorama/" + index} data-setbg={item.thumbnail} >
                                                <div className="gellery__text">
                                                    <span>{item.genere}</span>
                                                    <h3>{item.homeTitle}</h3>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                }
                           )
                       }
                    </div>
                </section>
            </React.Fragment>
        )
    }

}

export default Home;