import React from 'react';
import '../styles/style.css';

var loadjs = require('loadjs');

class About extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            settings: null
        };
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/data/settings.json`)
        .then((r) => r.json())
        .then((data) =>{
            this.setState({settings: data.about})
        })
        loadjs(['/js/vendor/jquery-3.2.1.min.js',
                '/js/jquery.slicknav.min.js', 
                '/js/jquery.magnific-popup.min.js',
                // '/js/owl.carousel.min.js',
                // '/js/mixitup.min.js',
                '/js/masonry.pkgd.min.js',
                '/js/jquery.bxslider/jquery.bxslider.min.js',
                '/js/jquery.bxslider/bxslider.js',
                // '/js/main.js',
           ], {returnPromise: true})
        .then(function() {window.fixOffcanvasMenu();})
        .catch(function(pathsNotFound) { console.log("error loading scripts"); console.log(pathsNotFound) });        
    }

    render() {
        var { settings } = this.state;
        return (
            <React.Fragment>
                <div className="about__page">
                    <div className="about__text">
                        <div className="container">
                            <div className="section__title">
                                {
                                    settings === null ? "" :
                                    settings.title.map((item, index) => 
                                        <h3 key={index}>{item}</h3>                                    
                                    )
                                }
                            </div>
                            <div className="row">
                                <div className="col-lg-9 m-auto wrap-new-line">
                                    <h5>{settings === null ? "" : settings.subtitle}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="team__section">
                        <div className="container">
                            <div className="team-container">
                                {
                                    settings === null ? "" :
                                    settings.team.map((member, index) => {
                                        return (                                        
                                            <div className="team__member" key={index}>
                                                <div className="member_pic">
                                                    <img src={member.image} alt="" />
                                                    <div className="member__social">
                                                        <a className="ms_facebook"  href={member.facebook}><i className="fa fa-facebook"></i></a>
                                                    </div>
                                                </div>
                                                <h4>{member.name}</h4>
                                                <p>{member.title}</p>
                                            </div>                                        
                                        )
                                    })
                                }
                            </div>
                            {/* <div className="row">
                                <div className="col-xl-3 col-lg-4 col-sm-6 offset-lg-2">
                                    <div className="team__member">
                                        <div className="member_pic">
                                            <img src="/images/team/mrsdios.png" alt="" />
                                            <div className="member__social">
                                                <a className="ms_facebook"  href="https://www.facebook.com/Wowmydios-100849088328550/"><i className="fa fa-facebook"></i></a>
                                            </div>
                                        </div>
                                        <h4>Mrs. Dios</h4>
                                        <p>Production</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-sm-6 offset-lg-2">
                                    <div className="team__member">
                                        <div className="member_pic">
                                            <img src="/images/team/mrdios.png" alt="" />
                                            <div className="member__social">
                                                <a className="ms_facebook"  href="https://www.facebook.com/Wowmydios-100849088328550/"><i className="fa fa-facebook"></i></a>
                                            </div>
                                        </div>
                                        <h4>Mr. Dios</h4>
                                        <p>Director</p>
                                    </div>
                                </div>

                            </div> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default About;