import React from 'react';
import Linkify from 'react-linkify';
import '../styles/style.css';

var loadjs = require('loadjs');

class OrderSent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        };
    }

    componentDidMount() {
        loadjs(['/js/vendor/jquery-3.2.1.min.js',
                '/js/jquery.slicknav.min.js', 
                '/js/jquery.magnific-popup.min.js',
                // '/js/owl.carousel.min.js',
                // '/js/mixitup.min.js',
                '/js/masonry.pkgd.min.js',
                '/js/jquery.bxslider/jquery.bxslider.min.js',
                '/js/jquery.bxslider/bxslider.js',
                // '/js/main.js',
           ], {returnPromise: true})
        .then(function() {window.fixOffcanvasMenu();})
        .catch(function(pathsNotFound) { console.log("error loading scripts"); console.log(pathsNotFound) });        
    }

    render() {
        return (
            <React.Fragment>
                <div class="about__page">
                    <div class="about__text">
                        <div class="container">
                            <div class="section__title">
                                <h3>Formal Order sent.</h3>
                                <h3>We can't wait for you to receive your Wow My Dios Diorama!</h3>
                                <h3>Talk to you soon.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default OrderSent;