import React from 'react';
import '../styles/style.css';
import PropTypes from 'prop-types';
import { NavLink, withRouter, Link } from "react-router-dom";

// Comments in react, in the render() or return() require an '{/*' and '*/}' to start and end a comment

class Header extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    render() {
        return (
            <React.Fragment>
                <div className="offcanvas-menu-overlay"></div>
                <div className="offcanvas-menu">
                    <Link to="/" className="site-logo">
                        <img src="/logos/logo-wowmydios.png" alt="" />
                    </Link>
                    <nav className="mobile__menu"></nav>
                </div>
                <header className="header">
                    <div className="header__warp">
                        <Link to="/" className="site-logo">
                            <img style={{height: '50px'}} src="/logos/logo-wowmydios.png" alt="" />
                        </Link>
                        <div className="header__right">
                            <nav className="main__menu">
                                <ul>
                                    <li><NavLink to="/home" activeClassName="menu--active">HOME</NavLink></li>
                                    <li><NavLink to="/dioramas" activeClassName="menu--active">DIORAMAS</NavLink></li>                                    
                                    <li><NavLink to="/about" activeClassName="menu--active">ABOUT</NavLink></li>
                                    <li><NavLink to="/faq" activeClassName="menu--active">F.A.Q.</NavLink></li>
                                    <li><NavLink to="/contact" activeClassName="menu--active">CONTACT</NavLink></li>
                                </ul>
                            </nav>
                            
                            <div className="header__switches">
                                {/* <a href="#" class="search-switch"><i class="fa fa-search"></i></a> */}
                                <a id="offcanvas_menu_switch" href="#" className="nav-switch"><i className="fa fa-bars"></i></a>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

Header.contextTypes = {
    router: PropTypes.object
};

export default withRouter(Header);