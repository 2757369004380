import React from 'react';
import '../styles/style.css';
import { Link, withRouter } from "react-router-dom";

class Footer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            settings: null
        };
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/data/settings.json`)
        .then((r) => r.json())
        .then((data) =>{
            this.setState({settings: data.global})
        })
    }

    render() {
        var { settings } = this.state;
        return (
            <footer className="footer__section">
                <div className="container-fluid">
                    <div className="row" style={{marginBottom: '0px'}}>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-8" style={{marginTop: '0px'}}>
                            <div className="footer__copyright__text">
                                <p>Copyright &copy; {new Date().getFullYear()} All rights reserved | Developed with <i className="fa fa-heart" aria-hidden="true"></i> by <a target="_blank" href="https://pcdev.pt">Pedro Cavaleiro</a></p>                                
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4" style={{marginTop: '3px'}}>
                            <div className="footer__social">
                                <a href="/disclaimer"><i style={{marginRight: '5px'}} className="fa fa-exclamation"></i> Disclaimer</a>
                                {
                                    settings === null ? "" : 
                                    settings.social.filter(function(item) { return item.showOnFooter }).map((item, index) => {
                                        return (
                                            <a key={index} href={item.url}><i style={{marginRight: '5px'}} className={"fa " + item.icon}></i> {item.text}</a>
                                        )
                                    })
                                }                                
                            </div>
                        </div>                        
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer